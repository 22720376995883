import React from "react";
import { graphql } from "gatsby";
import "./simple-page-template.scss";
import Markdown from "@components/markdown";
import File from "@components/file/file";

const SimplePageTemplate = props => {
	const {
		data: { strapiSimplepages: { body, title, documents } = {} } = {}
	} = props;

	let i = 0;

	return (
		<section className="simple-page full-width">
			<div className="grid-container">
				<div className="row">
					<div className="col-xs-12 col-md-offset-2 col-md-8 col-sm-offset-1 col-sm-10">
						<h2 className="about-us__header">{title}</h2>
					</div>
					{body && (
						<div className="col-xs-12 col-md-offset-2 col-md-6 col-sm-offset-1 col-sm-10">
							<Markdown source={body} />
						</div>
					)}
				</div>
				{documents && !!documents.length && (
					<div className="row">
						{documents.map(
							({
								name,
								file: { publicURL, prettySize: size, extension } = {}
							}) => {
								i += 1;
								return (
									<div
										key={i}
										className={`col-xs-12 ${
											i % 2
												? "col-md-offset-2 col-md-4 col-sm-offset-1 col-sm-5"
												: "col-md-4 col-sm-5"
										}`}
									>
										<File
											name={name}
											type={extension}
											size={size}
											link={publicURL}
											className="simple-page__file"
										/>
									</div>
								);
							}
						)}
					</div>
				)}
			</div>
		</section>
	);
};

export const pageQuery = graphql`
	query($path: String!) {
		strapiSimplepages(path: { eq: $path }) {
			body
			path
			title
			id
			documents {
				name
				file {
					publicURL
					prettySize
					extension
				}
			}
		}
	}
`;

export default SimplePageTemplate;
